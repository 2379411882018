import React, { useState, useEffect } from 'react';

const PlayersPrizeGiveaway = (props) => {
  const { eaglesCunt, show } = props;
  const [visible, setVisible] = useState(0);

  useEffect(() => {
    if (show && visible < 1) {
      return fadeIn();
    } else if (!show && visible > 0) {
      return fadeOut();
    }
  }, [show, visible]);

  const fadeIn = () => {
    const timer = setTimeout(() => {
      setVisible(visible + 0.2);
    }, 100);
    return () => clearTimeout(timer);
  };

  const fadeOut = () => {
    const timer = setTimeout(() => {
      setVisible(visible - 0.2);
    }, 100);
    return () => clearTimeout(timer);
  };

  let imageUrl = '/prize-images/divot_tool.png';
  let untilNextPrize = 10;
  let style = styles.divot_tool;
  if (eaglesCunt < 10) {
    untilNextPrize = 10 - eaglesCunt;
  } else if (eaglesCunt < 20) {
    imageUrl = '/prize-images/belt_bag.png';
    untilNextPrize = 20 - eaglesCunt;
    style = styles.belt_bag;
  } else if (eaglesCunt < 30) {
    imageUrl = '/prize-images/towel.png';
    untilNextPrize = 30 - eaglesCunt;
    style = styles.towel;
  } else if (eaglesCunt < 40) {
    imageUrl = '/prize-images/hat.png';
    untilNextPrize = 40 - eaglesCunt;
    style = styles.hat;
  } else {
    imageUrl = '/prize-images/yeti.png';
    untilNextPrize = 50 - eaglesCunt;
    style = styles.yeti;
  }

  return (
    <div style={{ ...styles.overlay, opacity: visible }}>
      <div style={styles.textContainer}>
        <div style={styles.textBig}>
          <span style={styles.number}>{untilNextPrize}</span> More Eagle{untilNextPrize > 1 ? 's' : ''}
        </div>
        <div style={styles.textSmall}>
          until the next PLAYERS<sup style={styles.superscript}>®</sup> prize
          giveaway.
        </div>
      </div>
      <div style={style}>
        <img src={imageUrl} alt="prize-image" style={styles.image} />
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '1936px',
    height: '352px',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 9999,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: '250px',
  },
  number: {
    color: 'white',
    backgroundColor: '#003061',
    paddingTop: '15px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  textBig: {
    fontSize: '100px',
    color: 'white',
    marginBottom: '0px',
    fontWeight: 'bold',
    fontFamily: 'MorganStanley',
    color: '#003061',
  },
  textSmall: {
    fontSize: '46px',
    color: 'white',
    marginBottom: '10px',
    fontWeight: 'bold',
    fontFamily: 'MorganStanley',
    color: '#003061',
  },
  superscript: {
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: '24px',
    verticalAlign: 'super',
  },
  divot_tool: {
    width: '600px',
    height: '600px',
    marginRight: '60px',
    marginTop: '260px',
  },
  belt_bag: {
    width: '600px',
    height: '600px',
    marginRight: '100px',
    marginTop: '300px',
  },
  towel: {
    width: '550px',
    height: '550',
    marginRight: '150px',
    marginTop: '350px',
  },
  hat: {
    width: '400px',
    height: '400px',
    marginRight: '300px',
    marginTop: '50px',
  },
  yeti: {
    width: '330px',
    height: '330px',
    marginRight: '300px',
    marginTop: '10px',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
};

export default PlayersPrizeGiveaway;
