import React from 'react';
import { Link } from 'react-router-dom';
import './home.css';

const Home = () => {
  return (
    <div>
      <div className="home-link">
        <Link to="/fan-tent-wide-screen" className="text-link">
          Fan Tent Widescreen LED (1936x352)
        </Link>
      </div>
      <div className="home-link">
        <Link to="/the-grove" className="text-link">
          The Grove (1920x1080)
        </Link>
      </div>
      <div className="home-link">
        <Link to="/the-grove-alternate" className="text-link">
          The Grove Alternate (1920x1080)
        </Link>
      </div>
      <div className="home-link">
        <Link to="/fan-tent-tv-screen" className="text-link">
          Fan Experience Tent (1920x1080)
        </Link>
      </div>
      <div className="home-link">
        <Link to="/hospitality-17" className="text-link">
          17 Hospitality Tent (1920x1080)
        </Link>
      </div>
      <div className="home-link">
        <Link to="/hospitality-17-thursday" className="text-link">
          17 Hospitality Tent - Thursday (1920x1080)
        </Link>
      </div>
      <div className="home-link">
        <Link to="/hospitality-17-friday" className="text-link">
          17 Hospitality Tent - Friday (1920x1080)
        </Link>
      </div>
      <div className="home-link">
        <Link to="/hospitality-17-saturday" className="text-link">
          17 Hospitality Tent - Saturday (1920x1080)
        </Link>
      </div>
      <div className="home-link">
        <Link to="/hospitality-18" className="text-link">
          18 Hospitality Tent (1920x1080)
        </Link>
      </div>
      <div className="home-link">
        <Link to="/hospitality-18-thursday" className="text-link">
          18 Hospitality Tent - Thursday (1920x1080)
        </Link>
      </div>
      <div className="home-link">
        <Link to="/hospitality-18-friday" className="text-link">
          18 Hospitality Tent - Friday (1920x1080)
        </Link>
      </div>
      <div className="home-link">
        <Link to="/hospitality-18-saturday" className="text-link">
          18 Hospitality Tent - Saturday (1920x1080)
        </Link>
      </div>
      <div className="home-link">
        <Link to="/ms-office" className="text-link">
          MS Office (1920x1080)
        </Link>
      </div>
      <div className="home-link">
        <Link to="/test" className="text-link">
          Test Page (1936x352)
        </Link>
      </div>
    </div>
  );
};

export default Home;
