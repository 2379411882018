import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { getFirstAndSecondDigits } from '../../utils';

const BallAnimation = (prop) => {
  const { eaglesCunt } = prop;
  const [secondDigit, setSecondDigit] = useState(-1);
  const [firstDigit, setFirstDigit] = useState(-1);
  const [playing, setPlaying] = useState(true);
  const [phase, setPhase] = useState(0);
  const playerRefs = {
    main: useRef(null),
    left: useRef(null),
    right: useRef(null),
  };

  useEffect(() => {
    if (secondDigit === -1 && firstDigit === -1) {
      resetVideos();
    }
  }, [eaglesCunt]);

  const handleVideoProgress = (state) => {
    if (state.playedSeconds > 5 && state.playedSeconds < 6 && phase === 0) {
      setPhase(1);
      setPlaying(false);
      console.log('Ball animation paused');
      setTimeout(() => {
        setPlaying(true);
        console.log('Ball animation continued');
      }, 1000);
    } else if (state.playedSeconds > 7 && phase === 1) {
      setPhase(0);
    }
  };

  const resetVideos = () => {
    if (playerRefs.main.current) playerRefs.main.current.seekTo(0);
    if (playerRefs.left.current) playerRefs.left.current.seekTo(0);
    if (playerRefs.right.current) playerRefs.right.current.seekTo(0);
    const { firstDigit, secondDigit } = getFirstAndSecondDigits(eaglesCunt);
    setFirstDigit(firstDigit);
    setSecondDigit(secondDigit);
  };

  return (
    <div style={styles.container}>
      <div style={styles.videoWrapper}>
        <ReactPlayer
          ref={playerRefs.main}
          url="/ball-animation-vertical/ball-animation.webm"
          muted={true}
          playing={playing}
          loop={false}
          width="100%"
          height="100%"
          style={styles.video}
          onProgress={handleVideoProgress}
          onEnded={resetVideos}
        />
        <ReactPlayer
          ref={playerRefs.left}
          url={`/ball-animation-vertical/LEFT ${firstDigit} VERT.webm`}
          muted={true}
          playing={playing}
          loop={false}
          width="100%"
          height="100%"
          style={styles.video}
        />
        <ReactPlayer
          ref={playerRefs.right}
          url={`/ball-animation-vertical/RIGHT ${secondDigit} VERT.webm`}
          muted={true}
          playing={playing}
          loop={false}
          width="100%"
          height="100%"
          style={styles.video}
        />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
    backgroundColor: 'transparent',
  },
  videoWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
};

export default BallAnimation;
