import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import ProgressBar from './components/progress-bar';
import EagleMadeAnimation from './components/eagle-made-animation';
import PlayersPrizeGiveaway from './components/players-prize-giveaway';
import CountEaglesWithUs from './components/count-eagles-with-us';

const PageToShow = {
  INIT: -1,
  COUNT_EAGLES_WITH_US: 0,
  PLAYERS_PRIZE_GIVEAWAY: 20,
  PROGRESS_BAR: 40,
};

const FanTentWideScreen = (prop) => {
  const { eaglesCunt } = prop;
  const [pageToShow, setPageToShow] = useState(PageToShow.INIT);
  const [eagleMade, setEagleMade] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const playerRef = useRef();

  const handleVideoProgress = (state) => {
    if ((state.playedSeconds > 0 && state.playedSeconds < 14) || (eaglesCunt >=50 && (state.playedSeconds > 0 && state.playedSeconds <= 36))) {
      setPageToShow(PageToShow.COUNT_EAGLES_WITH_US);
    } else if (state.playedSeconds >= 17 && state.playedSeconds <= 36) {
      setPageToShow(PageToShow.PLAYERS_PRIZE_GIVEAWAY);
    } else if (state.playedSeconds >= 38 && state.playedSeconds <= 153) {
      setPageToShow(PageToShow.PROGRESS_BAR);
    } else if (state.playedSeconds >= 157 && state.playedSeconds <= 170) {
      setPageToShow(PageToShow.COUNT_EAGLES_WITH_US);
    } else {
      setPageToShow(PageToShow.INIT);
    }
  };

  useEffect(() => {
    if (eagleMade) {
      const timer = setTimeout(() => {
        setPageToShow(PageToShow.INIT);
        setIsPlaying(false);
        playerRef.current?.seekTo(17, 'seconds');
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      setIsPlaying(true);
    }
  }, [eagleMade]);

  return (
    <div style={styles.container}>
      <ReactPlayer
        url="https://bitworthy-f7dec4aehddqcnag.z01.azurefd.net/tma-mstpc/Count%20Eagles%20v2.mp4"
        muted={true}
        playing={isPlaying}
        loop={true}
        width={styles.video.width}
        height={styles.video.height}
        onProgress={handleVideoProgress}
        ref={playerRef}
      />
      <CountEaglesWithUs show={pageToShow == PageToShow.COUNT_EAGLES_WITH_US} />
      <PlayersPrizeGiveaway
        eaglesCunt={eaglesCunt}
        show={pageToShow == PageToShow.PLAYERS_PRIZE_GIVEAWAY}
      />
      <ProgressBar
        eaglesCunt={eaglesCunt}
        config={config}
        show={pageToShow == PageToShow.PROGRESS_BAR}
      />
      <EagleMadeAnimation
        eagleMade={eagleMade}
        setEagleMade={setEagleMade}
        eaglesCunt={eaglesCunt}
      />
    </div>
  );
};

const styles = {
  container: {
    width: '1936px',
    height: '352px',
    backgroundColor: 'gray',
  },
  video: {
    width: '1936px',
    height: '352px',
  },
};

const config = {
  eagleMadeAnimationDuration: 25000, // seconds
  maxEagleCount: 50,
};

export default FanTentWideScreen;
