import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import ImageSlide from '../../components/slide/ImageSlide';
import VideoSlide from '../../components/slide/VideoSlide';
import CircularWithValueLabel from '../../components/circular-progress';
import EaglesTracker from '../../components/eagle-tracker';

const Hospitality17AppearancePage = (props) => {
  const { eaglesCunt } = props;
  const [isEagleTrackerLoading, setIsEagleTrackerLoading] = useState(true);
  const [isFirstSlideLoading, setIsFirstSlideLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (!isEagleTrackerLoading && !isFirstSlideLoading) {
      const timeoutId = setTimeout(() => {
        setIsLoading(false);
      }, 500);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isEagleTrackerLoading, isFirstSlideLoading]);

  useEffect(() => {
    if (isLoading) return;

    const timeoutId = setTimeout(() => {
      setIndex((index) => {
        let nextIndex = 0;
        if (index < Object.keys(slideIntervals).length) nextIndex = index + 1;
        console.log(`Active slide: ${nextIndex}`);
        return nextIndex;
      });
    }, slideIntervals[index]);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [index, isLoading]);

  const slideIntervals = {
    0: 1000, // Black Image
    1: 50000, // Grit to Vision Video
    2: 50, // Black Image
    3: 35000, // TVC Behind the Drive Video
    4: 50, // Black Image
    5: 57000, // Eagles Tracker
    6: 50, // Black Image
    7: 30000, // TVC Anthem Video
    8: 50, // Black Image
    9: 30000, // First Tee imagery Image
    10: 50, // Black Image
    11: 30000, // Special Appearance Image
    12: 50, // Black Image
    13: 30000, // Special Appearance Image
  };

  const customRenderItem = (item, props) => (
    <item.type {...item.props} {...props} />
  );

  return (
    <div className="App">
      {isLoading ? <CircularWithValueLabel /> : null}
      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
        transitionTime={3}
        stopOnHover={false}
        swipeable={false}
        useKeyboardArrows={false}
        showIndicators={false}
        autoPlay={false}
        renderItem={customRenderItem}
        selectedItem={index}
      >
        <ImageSlide key="black-1" url="/black.jpg" />
        <VideoSlide
          playing={index === 1}
          key="video-1"
          setIsLoading={setIsFirstSlideLoading}
          url="https://bitworthy-f7dec4aehddqcnag.z01.azurefd.net/tma-mstpc/grit.mp4"
        />
        <ImageSlide key="black-2" url="/black.jpg" />
        <VideoSlide
          playing={index === 3}
          key="video-2"
          url="https://bitworthy-f7dec4aehddqcnag.z01.azurefd.net/tma-mstpc/drive.mp4"
        />
        <ImageSlide key="black-3" url="/black.jpg" />
        <EaglesTracker
          eaglesCunt={eaglesCunt}
          playing={index === 5}
          loop={false}
          setIsLoading={setIsEagleTrackerLoading}
          backgroundUrl={
            'https://bitworthy-f7dec4aehddqcnag.z01.azurefd.net/tma-mstpc/eagle_tracker_loop.mp4'
          }
        />
        <ImageSlide key="black-4" url="/black.jpg" />
        <VideoSlide
          playing={index === 7}
          key="video-3"
          url="https://bitworthy-f7dec4aehddqcnag.z01.azurefd.net/tma-mstpc/anthem.mp4"
        />
        <ImageSlide key="black-5" url="/black.jpg" />
        <ImageSlide
          key="image-1"
          url="https://bitworthy-f7dec4aehddqcnag.z01.azurefd.net/tma-mstpc/First Tee Imagery_1920x1080.jpg"
        />
        <ImageSlide key="black-6" url="/black.jpg" />
        <ImageSlide
          key="image-2"
          url="https://bitworthy-f7dec4aehddqcnag.z01.azurefd.net/tma-mstpc/hospitality_thursday_0.jpg" // Special Appearance PTT Image
        />
        <ImageSlide key="black-7" url="/black.jpg" />
        <ImageSlide
          key="image-3"
          url="https://bitworthy-f7dec4aehddqcnag.z01.azurefd.net/tma-mstpc/hospitality_thursday_1.jpg" // Special Appearance PTT Image
        />
      </Carousel>
    </div>
  );
};

export default Hospitality17AppearancePage;
