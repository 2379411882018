import React, { useState, useEffect } from 'react';

const CountEaglesWithUs = (props) => {
  const { show } = props;
  const [visible, setVisible] = useState(0);

  useEffect(() => {
    if (show && visible < 1) {
      return fadeIn();
    } else if (!show && visible > 0) {
      return fadeOut();
    }
  }, [show, visible]);

  const fadeIn = () => {
    const timer = setTimeout(() => {
      setVisible(visible + 0.2);
    }, 100);
    return () => clearTimeout(timer);
  };

  const fadeOut = () => {
    const timer = setTimeout(() => {
      setVisible(visible - 0.2);
    }, 100);
    return () => clearTimeout(timer);
  };

  return (
    <div style={{ ...styles.overlay, opacity: visible }}>
      <div style={styles.text}>Count Eagles With Us</div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '1936px',
    height: '352px',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999,
    transition: 'opacity 1s ease-in-out',
  },
  text: {
    fontSize: '90px',
    color: 'white',
    marginBottom: '-5px',
    fontWeight: 'bold',
    fontFamily: 'MorganStanley',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
  },
};

export default CountEaglesWithUs;
