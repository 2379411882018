import React, { useRef, useEffect } from 'react';
import './slide.css';
import ReactPlayer from 'react-player';

const VideoSlide = (props) => {
  const { url, playing, muted = true, setIsLoading = () => {} } = props;
  const playerRef = useRef();

  useEffect(() => {
    if (!playing) {
      seekToStart();
    }
  }, [playing]);

  const seekToStart = () => {
    if (playerRef) {
      playerRef.current?.seekTo(0, 'seconds');
      console.log(`Reset video to start ${url}`);
    }
  };

  return (
    <div>
      <ReactPlayer
        ref={playerRef}
        className="video"
        url={url}
        muted={muted}
        playing={playing}
        width={1920}
        height={1080}
        onReady={() => setIsLoading(false)}
      />
    </div>
  );
};

export default VideoSlide;
