import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';

const EagleMadeAnimation = (props) => {
  const { eagleMade, setEagleMade, eaglesCunt } = props;
  const [visible, setVisible] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const playerRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isLoading) return;
    if (eaglesCunt <= 0 || eagleMade) {
      return;
    }
    setEagleMade(true);
  }, [eaglesCunt]);

  useEffect(() => {
    if (eagleMade && visible < 1) {
      return fadeIn();
    } else if (!eagleMade && visible > 0) {
      return fadeOut();
    }
  }, [eagleMade, visible]);

  const fadeIn = () => {
    const timer = setTimeout(() => {
      setVisible(visible + 0.2);
    }, 100);
    return () => clearTimeout(timer);
  };

  const fadeOut = () => {
    const timer = setTimeout(() => {
      setVisible(visible - 0.2);
    }, 100);
    return () => clearTimeout(timer);
  };

  const onEnded = () => {
    setEagleMade(false);
    if (playerRef.current) {
      playerRef.current.seekTo(0);
    }
  };

  let url =
    'https://bitworthy-f7dec4aehddqcnag.z01.azurefd.net/tma-mstpc/another_eagle_made.mp4';
  if (eaglesCunt == 10)
    url =
      'https://bitworthy-f7dec4aehddqcnag.z01.azurefd.net/tma-mstpc/10_eagles_made.mp4';
  else if (eaglesCunt == 20)
    url =
      'https://bitworthy-f7dec4aehddqcnag.z01.azurefd.net/tma-mstpc/20_eagles_made.mp4';
  else if (eaglesCunt == 30)
    url =
      'https://bitworthy-f7dec4aehddqcnag.z01.azurefd.net/tma-mstpc/30_eagles_made.mp4';
  else if (eaglesCunt == 40)
    url =
      'https://bitworthy-f7dec4aehddqcnag.z01.azurefd.net/tma-mstpc/40_eagles_made.mp4';
  else if (eaglesCunt == 50)
    url =
      'https://bitworthy-f7dec4aehddqcnag.z01.azurefd.net/tma-mstpc/50_eagles_made.mp4';

  return (
    <div style={{ ...styles.overlay, opacity: visible }}>
      <ReactPlayer
        ref={playerRef}
        url={url}
        muted={true}
        playing={!isLoading && eagleMade}
        loop={false}
        width={styles.video.width}
        height={styles.video.height}
        onEnded={onEnded}
      />
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '1936px',
    height: '352px',
    backgroundColor: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999,
  },
  video: {
    width: '100%',
    height: '100%',
  },
};

export default EagleMadeAnimation;
