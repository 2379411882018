import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import TheGrove from './pages/the-grove';
import TheGroveAlternate from './pages/the-grove-alternate';
import MSOffice from './pages/ms-offices';
import Hospitality17Page from './pages/hospitality-17';
import Hospitality18Page from './pages/hospitality-18';
import Hospitality17ThursdayPage from './pages/hospitality-17-thursday';
import Hospitality18ThursdayPage from './pages/hospitality-18-thursday';
import Hospitality17FridayPage from './pages/hospitality-17-friday';
import Hospitality18FridayPage from './pages/hospitality-18-friday';
import Hospitality17SaturdayPage from './pages/hospitality-17-saturday';
import Hospitality18SaturdayPage from './pages/hospitality-18-saturday';
import FanTentWideScreen from './pages/fan-tent-wide-screen';
import FanTentTvScreen from './pages/fan-tent-tv-screen';
import { useFetchEagleCount } from './hooks';
import './fonts/fonts.css';
import TestPage from './pages/test-page';

function App() {
  const { eagleCunt } = useFetchEagleCount(
    `${process.env.REACT_APP_EAGLE_COUNTER_URL}/api/getEagleCount`,
  );

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/fan-tent-wide-screen"
        element={<FanTentWideScreen eaglesCunt={eagleCunt} />}
      />
      <Route
        path="/fan-tent-tv-screen"
        element={<FanTentTvScreen eaglesCunt={eagleCunt} />}
      />
      <Route path="/the-grove" element={<TheGrove eaglesCunt={eagleCunt} />} />
      <Route path="/the-grove-alternate" element={<TheGroveAlternate eaglesCunt={eagleCunt} />} />
      <Route
        path="/hospitality-17"
        element={<Hospitality17Page eaglesCunt={eagleCunt} />}
      />
      <Route
        path="/hospitality-18"
        element={<Hospitality18Page eaglesCunt={eagleCunt} />}
      />
      <Route
        path="/hospitality-18-thursday"
        element={<Hospitality18ThursdayPage eaglesCunt={eagleCunt} />}
      />
      <Route
        path="/hospitality-18-friday"
        element={<Hospitality18FridayPage eaglesCunt={eagleCunt} />}
      />
      <Route
        path="/hospitality-18-saturday"
        element={<Hospitality18SaturdayPage eaglesCunt={eagleCunt} />}
      />
      <Route
        path="/hospitality-17-thursday"
        element={<Hospitality17ThursdayPage eaglesCunt={eagleCunt} />}
      />
      <Route
        path="/hospitality-17-friday"
        element={<Hospitality17FridayPage eaglesCunt={eagleCunt} />}
      />
      <Route
        path="/hospitality-17-saturday"
        element={<Hospitality17SaturdayPage eaglesCunt={eagleCunt} />}
      />
      <Route path="/ms-office" element={<MSOffice eaglesCunt={eagleCunt} />} />

      <Route path="/test" element={<TestPage eaglesCunt={eagleCunt} />} />
    </Routes>
  );
}

export default App;
