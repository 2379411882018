import React, { useState, useEffect } from 'react';
import BallAnimation from './ball-animation';

const ProgressBarAnimation = (props) => {
  const { eaglesCunt, config, show } = props;
  const [progress, setProgress] = useState(0);
  const [url, setUrl] = useState('wide-screen/scale_0.png');

  useEffect(() => {
    if (progress >= config.maxEagleCount) return;

    if (eaglesCunt < 2) {
      setProgress(2);
      return;
    }

    if (show && progress < eaglesCunt) {
      const timer = setTimeout(() => {
        setProgress(progress + 0.1);
      }, 70);
      return () => clearTimeout(timer);
    }
  }, [show, progress, eaglesCunt]);

  useEffect(() => {
    let init = 0;
    while (init + 10 < progress) init += 10;
    setUrl(`wide-screen/scale_${init}.png`);
  }, [progress]);

  useEffect(() => {
    if (show) return;

    const timer = setTimeout(() => {
      let init = 0;
      while (init + 10 < eaglesCunt) init += 10;
      setProgress(init);
    }, 1000);
    return () => clearTimeout(timer);
  }, [show]);

  return (
    <div style={styles.progressContainer}>
      <img src={url} alt="Progress" style={styles.image} />
      <div style={styles.progressBox}>
        <div
          style={{
            ...styles.progress,
            width: `${1833 * (progress / config.maxEagleCount)}px`,
          }}
        ></div>
      </div>
      <div
        style={{
          ...styles.overlay,
          right: `${1805 - 1833 * (progress / config.maxEagleCount)}px`,
        }}
      >
        <BallAnimation
          eaglesCunt={eaglesCunt}
          resetNumber={
            show &&
            (progress + 0.2 >= eaglesCunt ||
              progress + 0.2 >= config.maxEagleCount)
          }
          showNumber={
            show && (progress >= eaglesCunt || progress >= config.maxEagleCount)
          }
        />
      </div>
    </div>
  );
};

const styles = {
  progressContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '1936px',
    height: '352px',
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textContainer: {
    position: 'absolute',
    top: '40px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 10001,
    fontSize: '40px',
    color: '#003061',
    marginBottom: '10px',
    fontWeight: 'bold',
    fontFamily: 'MorganStanley',
  },
  videoContainer: {
    position: 'relative',
    width: '100%',
    height: 'auto',
  },
  progressBox: {
    zIndex: 10001,
    position: 'absolute',
    bottom: '128px',
    left: '48px',
    width: '100%',
    backgroundColor: 'transparent',
  },
  progress: {
    backgroundColor: '#003061',
    height: '20px',
    position: 'relative',
    borderRadius: '10px',
  },
  overlay: {
    position: 'absolute',
    top: '110px',
    width: '200px',
    height: '200px',
    zIndex: 10002,
  },
  image: {
    marginTop: '190px',
    position: 'absolute',
    left: '51px',
    width: '100%',
    height: 'auto',
  },
};

export default ProgressBarAnimation;
