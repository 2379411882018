import React, { useState } from 'react';
import EaglesTracker from '../../components/eagle-tracker';
import CircularWithValueLabel from '../../components/circular-progress';

const MSOffice = (prop) => {
  const { eaglesCunt } = prop;
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div>
      {isLoading ? <CircularWithValueLabel /> : null}
      <EaglesTracker
        eaglesCunt={eaglesCunt}
        setIsLoading={setIsLoading}
        playing={true}
        loop={true}
        backgroundUrl={
          'https://bitworthy-f7dec4aehddqcnag.z01.azurefd.net/tma-mstpc/eagle_tracker_loop-no_prizes.mp4'
        }
      />
    </div>
  );
};

export default MSOffice;
