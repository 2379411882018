import React, { useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import BallAnimation from '../ball-animation';

const EaglesTracker = (prop) => {
  const { eaglesCunt, playing, backgroundUrl, loop = false, setIsLoading = () => {} } = prop;
  const playerRef = useRef();

  useEffect(() => {
    if (playing) return;
    playerRef.current?.seekTo(0, 'seconds');
  }, [playing]);

  return (
    <div style={styles.container}>
      <div style={styles.videoWrapper}>
        <ReactPlayer
          ref={playerRef}
          url={backgroundUrl}
          muted={true}
          playing={playing}
          loop={loop}
          width="99.63%"
          height="99.63%"
          style={styles.video}
          onReady={() => setIsLoading(false)}
        />
        <div style={styles.overlay}>
          <BallAnimation eaglesCunt={eaglesCunt} />
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: '1920px',
    height: '1080px',
    backgroundColor: 'black',
    position: 'relative',
  },
  videoWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  video: {
    width: '100%',
    height: '100%',
  },
  overlay: {
    position: 'absolute',
    top: '50px',
    right: '85px',
    width: '450px',
    height: '450px',
    zIndex: 10,
  },
};

export default EaglesTracker;
