import React, { useState } from 'react';
import ProgressBar from '../fan-tent-wide-screen/components/progress-bar';
import PlayersPrizeGiveaway from '../fan-tent-wide-screen/components/players-prize-giveaway';
import CountEaglesWithUs from '../fan-tent-wide-screen/components/count-eagles-with-us';

const TestPage = (prop) => {
  const [eaglesCunt, setEaglesCunt] = useState(0);
  const [show, setShow] = useState(false);
  const [eagleMade, setEagleMade] = useState(false);

  const handleButtonClick1 = () => {
    setShow(!show);
  };
  const handleButtonClick2 = () => {
    setEagleMade(!eagleMade);
  };
  const handleInputChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value)) {
      setEaglesCunt(value);
    }
  };

  return (
    <div>
      <div style={styles.container}>
        {/* <CountEaglesWithUs show={show} eaglesCunt={eaglesCunt} /> */}
        <ProgressBar
          eaglesCunt={eaglesCunt}
          config={config}
          show={show}
          eagleMade={eagleMade}
        />
        {/* <PlayersPrizeGiveaway show={show} eaglesCunt={eaglesCunt}/> */}
      </div>
      <div style={styles.buttonContainer}>
        <button style={styles.button} onClick={handleButtonClick2}>
          EagleMade
        </button>
        <button style={styles.button} onClick={handleButtonClick1}>
          Show
        </button>
        <input
          type="number"
          value={eaglesCunt}
          onChange={handleInputChange}
          style={styles.input}
        />
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: '1936px',
    height: '352px',
    backgroundColor: 'gray',
    position: 'relative',
  },
  buttonContainer: {
    marginTop: '20px', // Add some space between the container and the buttons
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    margin: '5px',
    padding: '10px 20px',
    fontSize: '16px',
  },
  input: {
    margin: '5px',
    padding: '10px',
    fontSize: '16px',
    width: '100px',
  },
  video: {
    width: '1936px',
    height: '352px',
  },
};

const config = {
  eagleMadeAnimationDuration: 25000, // seconds
  maxEagleCount: 50,
};

export default TestPage;