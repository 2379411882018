import React, { useState, useEffect } from 'react';
import ProgressBarAnimation from './progress-bar-animation';

const ProgressBar = (props) => {
  const { show } = props;
  const [visible, setVisible] = useState(0);

  useEffect(() => {
    if (show && visible < 1) {
      return fadeIn();
    } else if (!show && visible > 0) {
      return fadeOut();
    }
  }, [show, visible]);

  const fadeIn = () => {
    const timer = setTimeout(() => {
      setVisible(visible + 0.2);
    }, 100);
    return () => clearTimeout(timer);
  };

  const fadeOut = () => {
    const timer = setTimeout(() => {
      setVisible(visible - 0.2);
    }, 100);
    return () => clearTimeout(timer);
  };

  return (
    <div style={{ ...styles.progressContainer, opacity: visible }}>
      <div style={{ ...styles.textContainer, opacity: visible }}>
        THE PLAYERS<sup style={styles.superscript}>®</sup> Championship Eagles
        Count
      </div>
      <ProgressBarAnimation {...props} />
    </div>
  );
};

const styles = {
  progressContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '1936px',
    height: '352px',
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textContainer: {
    position: 'absolute',
    top: '40px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 10001,
    fontSize: '52px',
    color: '#003061',
    marginBottom: '10px',
    fontWeight: 'bold',
    fontFamily: 'MorganStanley',
    width: '1050px',
  },
  superscript: {
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: '20px',
    verticalAlign: 'super',
  },
  videoContainer: {
    position: 'relative',
    width: '100%',
    height: 'auto',
  },
  progressBox: {
    zIndex: 10001,
    position: 'absolute',
    bottom: '131px',
    left: '24px',
    width: '100%',
    backgroundColor: 'transparent',
  },
  progress: {
    backgroundColor: '#003061',
    height: '20px',
    position: 'relative',
    borderRadius: '10px',
  },
  overlay: {
    position: 'absolute',
    top: '60px',
    width: '300px',
    height: '300px',
    zIndex: 10002,
  },
};

export default ProgressBar;
