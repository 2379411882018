import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { getFirstAndSecondDigits } from '../../../../../../utils';

const BallAnimation = (prop) => {
  const { eaglesCunt, showNumber, resetNumber } = prop;
  const [secondDigit, setSecondDigit] = useState(0);
  const [firstDigit, setFirstDigit] = useState(0);
  const playerRefs = {
    main: useRef(null),
    left: useRef(null),
    right: useRef(null),
  };

  useEffect(() => {
    if (eaglesCunt > 0) {
      resetVideos();
    }
  }, [eaglesCunt]);

  useEffect(() => {
    if (resetNumber) resetVideos();
  }, [resetNumber]);

  const resetVideos = () => {
    if (playerRefs.main.current) playerRefs.main.current.seekTo(0);
    if (playerRefs.left.current) playerRefs.left.current.seekTo(0);
    if (playerRefs.right.current) playerRefs.right.current.seekTo(0);
    const { firstDigit, secondDigit } = getFirstAndSecondDigits(eaglesCunt);
    setFirstDigit(firstDigit);
    setSecondDigit(secondDigit);
  };

  return (
    <div style={styles.container}>
      <div style={styles.videoWrapper}>
        <ReactPlayer
          url="https://bitworthy.blob.core.windows.net/tma-mstpc/ball continuous linear roll for trey.webm"
          muted={true}
          playing={true}
          loop={true}
          width="100%"
          height="100%"
          style={{ ...styles.video, opacity: showNumber ? 0 : 1 }}
        />
        <ReactPlayer
          ref={playerRefs.main}
          url="https://bitworthy.blob.core.windows.net/tma-mstpc/10 second golfball wait.webm"
          muted={true}
          playing={true}
          loop={false}
          width="100%"
          height="100%"
          style={{ ...styles.video, opacity: showNumber ? 1 : 0 }}
          onEnded={resetVideos}
        />
        <ReactPlayer
          ref={playerRefs.left}
          url={`https://bitworthy.blob.core.windows.net/tma-mstpc/LEFT ${firstDigit}.webm`}
          muted={true}
          playing={true}
          loop={false}
          width="100%"
          height="100%"
          style={{ ...styles.video, opacity: showNumber ? 1 : 0 }}
        />
        <ReactPlayer
          ref={playerRefs.right}
          url={`https://bitworthy.blob.core.windows.net/tma-mstpc/RIGHT ${secondDigit}.webm`}
          muted={true}
          playing={true}
          loop={false}
          width="100%"
          height="100%"
          style={{ ...styles.video, opacity: showNumber ? 1 : 0 }}
        />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
    backgroundColor: 'transparent',
  },
  videoWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
};

export default BallAnimation;
